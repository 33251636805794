import { Badge, PageHeaderWrapper } from "@clipboard-health/ui-components";
import { Stack } from "@mui/material";

import { APP_V2_USER_EVENTS, logEvent } from "../../../lib/analytics";
import { IconButton } from "../../components/IconButton";
import { PickerButton } from "../../components/PickerButton";
import { getTimeSlotLabel } from "../../Shift/getTimeSlotLabel";
import { ShiftDiscoveryCalloutsContainer } from "../CalloutsContainer";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import {
  SHIFT_DISCOVERY_CALENDAR_PATH,
  SHIFT_DISCOVERY_FILTERS_PATH,
  SHIFT_DISCOVERY_WORKPLACES_LIST_MODAL_PATH,
} from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { useWorkplaceModalsDataContext } from "../useWorkplaceModalsDataContext";

interface ListViewPageHeaderProps {
  priorityShiftsCount: number;
  urgentShiftsCount: number;
  blockShiftsCount: number;
  homeHealthEnabled?: boolean;
  homeHealthCount?: number;
}

export function ListViewPageHeader(props: ListViewPageHeaderProps) {
  const {
    priorityShiftsCount,
    urgentShiftsCount,
    blockShiftsCount,
    homeHealthEnabled,
    homeHealthCount,
  } = props;

  const { navigateToModal } = useShiftModalsDataContext();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  const { shiftTimeSlots, dates, appliedFiltersCount } = useShiftDiscoveryUserFiltersContext();

  const workplacesListPath = generateModalPath(SHIFT_DISCOVERY_WORKPLACES_LIST_MODAL_PATH);

  return (
    <PageHeaderWrapper variant="tertiary" sx={{ paddingBottom: 1 }}>
      <ShiftDiscoveryCalloutsContainer
        priorityShiftsCount={priorityShiftsCount}
        urgentShiftsCount={urgentShiftsCount}
        blockShiftsCount={blockShiftsCount}
        homeHealthEnabled={homeHealthEnabled}
        homeHealthCount={homeHealthCount}
        sx={{ px: 5, paddingTop: 1, paddingBottom: 3 }}
      />

      <Stack direction="row" alignItems="center" sx={{ paddingBottom: 3, px: 5 }}>
        <PickerButton
          fullWidth
          size="medium"
          aria-label="Calendar View Navigation"
          primaryText={dates.length === 0 ? "Calendar" : `${dates.length} dates filtered`}
          secondaryText={
            shiftTimeSlots.length === 0 || shiftTimeSlots.length > 2
              ? "All time slots"
              : shiftTimeSlots.map((slot) => getTimeSlotLabel(slot)).join(", ")
          }
          startIconType="calendar-navigation"
          isSelected={dates.length > 0}
          sx={{ marginRight: 3 }}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_OPENED);
            navigateToModal(SHIFT_DISCOVERY_CALENDAR_PATH);
          }}
        />

        <Badge
          count={appliedFiltersCount}
          overlap="circular"
          color="primary"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <IconButton
            variant="outlinedHeavy"
            aria-label="Open Filters Button"
            size="small"
            iconType="slider-settings"
            isSelected={appliedFiltersCount > 0}
            sx={{ boxShadow: "none !important" }}
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_VIEWED);
              navigateToModal(SHIFT_DISCOVERY_FILTERS_PATH);
            }}
          />
        </Badge>

        <IconButton
          variant="outlinedHeavy"
          aria-label="Workplaces Search"
          size="small"
          iconType="building"
          sx={{ boxShadow: "none !important" }}
          href={workplacesListPath}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.BROWSE_WORKPLACES_OPENED);
          }}
        />
      </Stack>
    </PageHeaderWrapper>
  );
}
