import { isDefined } from "@clipboard-health/util-ts";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";
import { parseISO } from "date-fns";

import { useGetOpenShiftsForDates } from "../../Shift/Open/useGetOpenShiftsForDates";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";

interface UseListViewOpenShiftsDataProps {
  distance?: number;
}

export function useListViewOpenShiftsData(props: UseListViewOpenShiftsDataProps) {
  const { distance = DEFAULT_DISTANCE_IN_MILES_FILTER } = props;
  const { dates: dateStrings } = useShiftDiscoveryUserFiltersContext();

  const workerGeoLocation = useWorkerGeoLocation();

  const dates = dateStrings.map((dateString) => parseISO(dateString));

  return useGetOpenShiftsForDates({
    dates,
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      area: {
        latitude: workerGeoLocation?.latitude ?? 0,
        longitude: workerGeoLocation?.longitude ?? 0,
        radiusInMiles: distance,
      },
    },
    enabled: isDefined(workerGeoLocation),
  });
}
