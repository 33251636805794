import { getTheme } from "@clipboard-health/ui-theme";
import { ThemeProvider } from "@mui/material";
import { FindCases } from "@src/appV2/ExperimentalHomeHealth/FindCases/FindCases";
import { getTheme as getLegacyTheme } from "@src/appV2/lib/Theme/theme";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { useRef } from "react";

import { PageWrapper } from "../../components/PageWrapper";

export function ShiftDiscoveryHomeHealthShiftsPage() {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper variant="tertiary">
        <StickyScrollPageHeader
          hideBackButton
          title="Find Work"
          isLoading={false}
          scrollContainerRef={scrollRef}
        />
        <ThemeProvider theme={getLegacyTheme("light")}>
          <FindCases />
        </ThemeProvider>
      </PageWrapper>
    </ThemeProvider>
  );
}
