import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useHomeHealthVisibility } from "@src/appV2/ExperimentalHomeHealth/BookedCases/api/useHomeHealthVisibility";
import { EnforceLocationPermissionsContainer } from "@src/appV2/LocationPermission/EnforceLocationPermissionsContainer";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

import { invalidateShifts } from "../../invalidateShifts";
import { MissedShifts } from "../../Shift/MissedShift/MissedShifts";
import { OpenShiftListDataProvider } from "../../Shift/Open/useOpenShiftListDataContext";
import { VirtualShiftListContextProvider } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { useScrollToTopOnStatusTap } from "../../useScrollToTopOnStatusTap";
import { ClearFiltersButton } from "../Filters/ClearFiltersButton";
import { ShiftDiscoverySearchModeSwitcher } from "../SearchModeSwitcher";
import { ListViewPageOpenShiftsList } from "./List";
import { ListViewPageHeader } from "./PageHeader";
import { PullToRefresh } from "./PullToRefresh";
import { useFilteredListViewOpenShiftsData } from "./useFilteredListViewOpenShiftsData";

export function ShiftDiscoveryListViewPage() {
  const queryClient = useQueryClient();

  const {
    data: {
      filteredOpenShifts,
      unfilteredOpenShifts,
      workplacesMap,
      rateNegotiationsMap,
      priorityShiftsCount,
      urgentShiftsCount,
      blockShiftsCount,
      timeSlotFilters,
    },
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    isError: openShiftsIsError,
    pagination,
    daysCount,
  } = useFilteredListViewOpenShiftsData();

  const { isHomeHealthEnabled, homeHealthCount } = useHomeHealthVisibility();

  const stackRef = useRef<HTMLDivElement>(null);

  useScrollToTopOnStatusTap(stackRef);

  const [shouldInfiniteScroll, setShouldInfiniteScroll] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      // To prevent an infinite loop of infinite scrolling at idle, we should
      // allow infinite scrolling only when the user has scrolled to any amount
      if ((stackRef.current?.scrollTop ?? 0) > 0) {
        if (!shouldInfiniteScroll) {
          setShouldInfiniteScroll(true);
        }
      } else if (shouldInfiniteScroll) {
        setShouldInfiniteScroll(false);
      }
    };

    const element = stackRef.current;

    if (isDefined(element)) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (isDefined(element)) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [shouldInfiniteScroll]);

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.tertiary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <EnforceLocationPermissionsContainer />
      <OpenShiftListDataProvider
        openShifts={filteredOpenShifts}
        workplacesMap={workplacesMap}
        rateNegotiationsMap={rateNegotiationsMap}
        isOpenShiftsLoading={openShiftsIsLoading}
        isOpenShiftsError={openShiftsIsError}
        isOpenShiftsSuccess={openShiftsIsSuccess}
        canLoadMoreShifts={Boolean(pagination && shouldInfiniteScroll && pagination?.hasNextPage)}
        isLoadingMoreShifts={pagination?.isFetchingNextPage ?? false}
        loadMoreShifts={() => {
          void pagination?.fetchNextPage();
        }}
      >
        <VirtualShiftListContextProvider
          timeSlotFilters={timeSlotFilters}
          shifts={filteredOpenShifts}
          workplacesMap={workplacesMap}
        >
          <ListViewPageHeader
            homeHealthEnabled={isHomeHealthEnabled}
            priorityShiftsCount={priorityShiftsCount}
            urgentShiftsCount={urgentShiftsCount}
            blockShiftsCount={blockShiftsCount}
            homeHealthCount={homeHealthCount}
          />

          <PullToRefresh
            scrollRef={stackRef}
            onRefresh={async () => {
              await invalidateShifts(queryClient);
            }}
          />
          <MissedShifts />

          <Stack
            ref={stackRef}
            data-testid="list-view-page-scroll-container"
            sx={{
              px: 5,
              overflow: "auto",
              flex: 1,
            }}
          >
            <ListViewPageOpenShiftsList daysCount={daysCount} scrollRef={stackRef} />
          </Stack>

          <ShiftDiscoverySearchModeSwitcher>
            <ClearFiltersButton allOpenShiftsCount={unfilteredOpenShifts.length} />
          </ShiftDiscoverySearchModeSwitcher>
        </VirtualShiftListContextProvider>
      </OpenShiftListDataProvider>
    </Stack>
  );
}
