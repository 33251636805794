import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { SelectableBox } from "@src/appV2/redesign/components/SelectableBox";

import { TextButton } from "../../components/TextButton";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { SHIFT_DISCOVERY_CALENDAR_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";

interface ListViewPageOpenShiftsEmptyStateProps {
  daysCount: number;
}

export function ListViewPageOpenShiftsEmptyState(props: ListViewPageOpenShiftsEmptyStateProps) {
  const { daysCount } = props;

  const { navigateToModal } = useShiftModalsDataContext();
  const { appliedFiltersCount } = useShiftDiscoveryUserFiltersContext();

  return (
    <Stack sx={{ flex: 1 }} justifyContent="center" spacing={5}>
      <Title semibold variant="h4" component="h3" sx={{ textWrap: "balance", textAlign: "center" }}>
        {appliedFiltersCount > 0
          ? `There are no open shifts for that search`
          : `There are no open shifts in the next ${daysCount} days`}
      </Title>

      {appliedFiltersCount === 0 ? (
        <TextButton
          sx={{ alignSelf: "center" }}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALENDAR_OPENED);
            navigateToModal(SHIFT_DISCOVERY_CALENDAR_PATH);
          }}
        >
          Load more dates
        </TextButton>
      ) : (
        <Text
          semibold
          sx={(theme) => ({ textAlign: "center", color: theme.palette.text.secondary })}
        >
          Try clearing or updating your filters
        </Text>
      )}

      <Stack spacing={3} sx={{ pt: 8 }}>
        <Text semibold>You can also:</Text>
        <SelectableBox
          withCallToActionIcon
          href={DeprecatedGlobalAppV1Paths.REFERRAL}
          startIconType="user-add"
        >
          Refer a professional
        </SelectableBox>

        <SelectableBox
          withCallToActionIcon
          href={DeprecatedGlobalAppV1Paths.WORKPLACE_REFERRALS}
          startIconType="building"
        >
          Refer a workplace
        </SelectableBox>
      </Stack>
    </Stack>
  );
}
