import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { useIsShiftBlocksEnabled } from "@src/appV2/redesign/Shift/Block/useIsShiftBlocksEnabled";

import { isPriorityShift } from "../../Shift/Priority/isPriorityShift";
import { useGetMultiLicenseAggregatedBlocks } from "../Blocks/useGetMultiLicenseAggregatedBlocks";
import { filterOpenShifts } from "../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { useUrgentShiftsData } from "../UrgentShifts/useUrgentShiftsData";
import { useListViewOpenShiftsData } from "./useListViewOpenShiftsData";

export function useFilteredListViewOpenShiftsData() {
  const isShiftBlocksEnabled = useIsShiftBlocksEnabled();

  const {
    distance,
    // Filtering by timeslot happens at the list rendering level
    // because each date can have a different set of timeslots
    shiftTimeSlots: timeSlotFilters,
    ...localUserFilters
  } = useShiftDiscoveryUserFiltersContext();

  const { filters: shiftBlockFilters } = useWorkerPreferences();

  const {
    shifts = [],
    workplacesMap = new Map(),
    rateNegotiationsMap = new Map(),
    ...openShiftsDataResult
  } = useListViewOpenShiftsData({ distance });

  // The open shifts data doesn't include all urgent shifts, so we need to fetch them separately
  const { shifts: urgentShifts } = useUrgentShiftsData();

  const { data: blockShifts } = useGetMultiLicenseAggregatedBlocks(
    {
      maxDistanceMiles: shiftBlockFilters.distance,
    },
    {
      enabled: isShiftBlocksEnabled,
    }
  );

  const priorityShifts = shifts.filter((shift) =>
    isPriorityShift({
      priorityTill: shift.attributes.priorityTill,
      window: shift.attributes.window,
    })
  );

  return {
    data: {
      filteredOpenShifts: filterOpenShifts({ shifts }, localUserFilters),
      unfilteredOpenShifts: shifts,
      workplacesMap,
      rateNegotiationsMap,
      urgentShiftsCount: urgentShifts.length,
      priorityShiftsCount: priorityShifts.length,
      blockShiftsCount: blockShifts?.data.length ?? 0,
      timeSlotFilters,
    },
    ...openShiftsDataResult,
  };
}
